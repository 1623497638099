<template>
    <div>
        <navbar />
        <tabbar :arr="categoryArr" v-on:clickTab="clickTabbar" />
        <div class="home-view-s">
            <van-list v-model="isLoading" :finished="finished" finished-text="我是有底线的" @load="onLoad" :error.sync="error" error-text="请求失败，点击重新加载">
                <div class="home-view">
                    <div v-for="item in arr" :key="item.id" @click="clickHome(item.id)" class="items">
                        <div class="item">
                            <div class="home-view-date">{{ item.publish_at }}</div>
                            <div class="home-view-image" :style="{ backgroundImage: 'url(' + item.cover + ')' }"></div>
                            <div class="home-view-title">
                                <div class="text">
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import navbar from "../components/NavBar"
import tabbar from "../components/tabbar"
export default {
    name: "home",
    data() {
        return {
            arr: [],
            categoryArr: [],
            page: 1,
            isLoading: false,
            finished: false,
            refreshing: false, //刷新
            error: false
        }
    },
    components: { navbar, tabbar },
    mounted() {
        this.getAllArticles()
        this.getTabBarList()
    },
    methods: {
        onLoad() {
            setTimeout(() => {
                this.getAllArticles(this.page + 1)
            }, 500)
        },
        getAllArticles(v) {
            this.isLoading = true
            this.$http
                .get(`/articles?page=${v ? v : 1}&per_page=6`)
                .then(res => {
                    if (res.data.meta.to === res.data.meta.total) {
                        this.finished = true
                    } else {
                        this.finished = false
                    }

                    if (Array.isArray(res.data.data)) {
                        const list = res.data.data.filter(item => {
                            return !this.arr.find(it => it.id === item.id)
                        })
                        this.arr = this.arr.concat(list)
                    }

                    this.page = res.data.meta.current_page
                })
                .finally(() => {
                    this.isLoading = false
                })
                .catch(() => {
                    this.error = true
                })
        },
        clickHome(v) {
            this.$router.push({ name: "ArticleInfo", query: { id: v } })
        },
        getTabBarList() {
            this.$http.get("/article_categories").then(res => {
                this.categoryArr = res.data.data
            })
        },
        clickTabbar(v) {
            console.log(v)
            this.$router.push({ name: "Article", query: { id: v } })
        }
    }
}
</script>

<style lang="less">
.home-view {
    margin-top: 180px;
    padding-left: 48px;
    padding-right: 48px;
    text-align: center;

    &-date {
        font-size: 36px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 34px;
    }

    &-image {
        width: 984px;
        height: 406px;
        background-size: 100% 100%;
    }

    &-img {
        width: 100%;
        height: 100%;
    }

    .item {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    &-title {
        background: #004f9f;
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        padding: 40px 32px;
        text-align: left;
        max-height: 100px;

        .text {
            line-height: 50px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}
.home-view-s {
    padding-bottom: 250px;
}
.van-list__finished-text {
    color: #242424;
    margin-top: 30px;
}
.item {
    margin-top: 82px;
}
</style>
