<template>
    <div></div>
</template>

<script>
import qs from "qs"

export default {
    methods: {
        login() {
            const ua = window.navigator.userAgent.toLowerCase()
            // 如果没有token
            if (!localStorage.getItem("token")) {
                const query = qs.parse(location.search.replace("?", ""))

                // 如果地址栏中有code
                if (query && query.code) {
                    let type = "official_account"

                    // 有code
                    console.log("有code")
                    if (/MicroMessenger/i.test(ua)) {
                        console.log("???")
                        if (/wxwork/i.test(ua)) {
                            console.log("企业微信登录")
                            type = "work"
                        }

                        this.$http
                            .get(`/auth/login`, {
                                params: {
                                    type,
                                    code: query.code
                                }
                            })
                            .then(res => {
                                console.log("微信登录成功", this.$route.query)
                                localStorage.setItem("token", res.data.token)
                                const URI = new URL(location.origin)
                                URI.pathname = location.pathname

                                URI.hash = "#/"
                                if (this.$route.query.redirect) {
                                    URI.hash = `#${this.$route.query.redirect}`
                                }
                                location.href = URI.toString()
                            })
                    }
                } else {
                    // if (/MicroMessenger/i.test(ua)) {
                    //
                    // }
                    // 没有code 去授权登录
                    let type = "official_account"
                    // 企业微信
                    if (/wxwork/i.test(ua)) {
                        console.log("企业微信授权")
                        type = "work"
                    }

                    let URI = ""
                    //企业微信
                    if (type === "work") {
                        const url = "https://fuchsconnect.fuchs.com.cn/h5/#/login?redirect="
                        if (this.$route.query.redirect) {
                            URI = url + this.$route.query.redirect
                        } else {
                            URI = url
                        }
                    } else {
                        const url = "https://fuchswechat.fuchs.com.cn/work_weixin/#/login?redirect="
                        if (this.$route.query.redirect) {
                            URI = url + this.$route.query.redirect
                        } else {
                            URI = url
                        }
                    }
                    // let URI = "https://fuchswechat.fuchs.com.cn/work_weixin/#/login?redirect="
                    // if (this.$route.query.redirect) {
                    //     if (this.$route.query.redirect) {
                    //         URI = URI + this.$route.query.redirect
                    //     } else {
                    //         URI = URI + this.$route.fullPath
                    //     }
                    // }

                    this.$http
                        .get(`/auth/login_url`, {
                            params: {
                                type,
                                callback_url: URI.toString()
                            }
                        })
                        .then(response => {
                            console.log(response)
                            window.location.href = response.data.url
                        })
                }
            } else {
                this.$router.replace("/")
            }
        }
    },
    mounted() {
        this.login()
    }
}
</script>

<style scoped></style>
